'use strict';

;(function($, document, window, app) {
    window.app = app;

    /**
    *    Init App
    */
   app.init = function() {
        app.$doc                = $(document);
        app.$win                = $(window);
        app.$wrapper            = $('.wrapper');
        app.$header             = $('.header');
        app.$subnav             = $('.nav-large');
        app.$ssTrigger            = $('.js-scrollspy');
        app.$parallaxElements   = $('.intro .intro__background');
        app.$uncoverElements    = $('.uncover');
        app.$splitTextElements  = $('.text-split');
        app.$accordions         = $('.accordion');
        app.$animateElements    = $('.animate');
        app.classes             = {
            visible: 'visible',
            open: 'open',
            animated: 'animated'
        };
        app.loading             = false;

        app.windowEvents();
        app.stickyHeader();
        app.parallax();
        app.jsClass();
        app.uncover();
        app.startSliders();
        app.splitText();
        app.startAccordions();
        app.startIsotope();
        app.scrollAnimations();
        app.handleVideos();
        app.stickyNav();
        app.scrollSpy();
        app.ajaxLoad();
        app.magnificShare();
   };

    /**
    *   Window Events
    */
    app.windowEvents = function () {
        app
            .$win
              .on('load scroll', function () {
                  app.winST = app.$win.scrollTop();
              });
    };

    /**
    *    Sticky Header
    */
    app.stickyHeader = function () {
        app
            .$win
                .on('load scroll', function() {
                    app
                        .$header
                          .toggleClass('scrolled', app.winST > 0);
                });
    };

    /**
    * Parallax
    */
    app.parallax = function() {
        app
            .$win
                .on('load scroll', function() {
                    app
                        .$parallaxElements
                            .each(function() {
                                var $element = $(this);
                                var $parent = $element.parent();

                                if($parent.offset().top + $parent.outerHeight() > app.winST) {
                                    $element.css({
                                        'transform': 'translate3d(0,' + app.winST * -0.2 + 'px, 0)'
                                    });
                                }
                            });
                });
    };

    /**
    *    JS Class
    */
    app.jsClass = function() {
            app
                .$doc
                    .on('click', '.js-class', function(e) {
                        e.preventDefault();

                        var $button = $(this);
                        var $target = $($button.data('target'));
                        var type = $button.data('type');
                        var className = $button.data('class');

                        switch(type) {
                            case 'add':
                                $target.addClass(className);
                                break;
                            case 'remove':
                                $target.removeClass(className);
                                break;
                            default:
                                $target.toggleClass(className);
                                break;
                        }
                    });
    };

   /**
   *    Uncover Animation
   */
    app.uncover = function() {
            app
                .$uncoverElements
                    .each(function() {
                        var $uncover = $(this);
                        var $slidesLength = $uncover.data('slides');
                        var delay = $uncover.data('delay');

                        $uncover.append('<div class="uncover__slides"></div>');

                        var $uncoverSlides = $uncover.find('.uncover__slides');

                        for(var i = 0; i < $slidesLength; i++) {
                            $uncoverSlides.append('<div class="uncover__slide"></div>');
                        }

                        $uncoverSlides
                            .find('.uncover__slide')
                                .each(function(i) {
                                    $(this).css('animation-delay', i * delay + 's');
                                });
                    });
    };


  /**
  *    Sliders
  */
    app.startSliders = function() {

        // Slider Quotes
        $('.slider-quotes').each(function() {
            var $slider = $(this);
            var $slides = $slider.find('.slider__slides');

            $slides.slick({
                arrows: false,
                dots: true,
                appendDots: $slider.find('.slider__paging'),
                fade: true,
                autoplay: true
            });

            $slides.on('swipe', function() {
                $slides.slick('slickSetOption', 'autoplay', false, true);
            });

            $slider.find('.slick-dots li').on('click', function() {
                $slides.slick('slickSetOption', 'autoplay', false, true);
            });
        });

        // Widgets Slider
        $('.widget-split .widget__slider').each(function() {
            var $slider = $(this);
            var $slides = $slider.find('.widget__slides');

            $slides.slick({
                appendArrows: $slider.find('.widget__slider-actions'),
                draggable: false,
                swipe: false
            });
        });

        // Section sliders
        $('.section-slider').each(function() {
            var $slider = $(this);
            var $slides = $slider.find('.section-slides');

            $slides.slick({
                arrows: false,
                dots: false,
                fade: true,
                adapativeHeight: true
            });

            $slider
                .find('.slider__prev')
                    .on('click', function(e) {
                        e.preventDefault();
                        $slides.slick('slickPrev');
                    });

            $slider
                .find('.slider__next')
                    .on('click', function(e) {
                        e.preventDefault();
                        $slides.slick('slickNext');
                    });
        });
    };

    /**
    *    Split Text Animation
    */
    app.splitText = function() {
        app
            .$splitTextElements
                .each(function() {
                    var $this = $(this);
                    var text = $this.text().split(/[\r\n]+/g);
                    var wrappedText = [];

                    text.forEach(function (textPart) {
                        if(/\S/.test(textPart)) {
                            wrappedText.push('<div> <span>' + textPart + '</span> </div>');
                        }

                        $this.html(wrappedText);
                    });
                });
    };

    /**
    *    Accordions
    */
    app.startAccordions = function() {
        app
            .$accordions
                .find('.accordion__head')
                    .on('click', function() {
                        $(this)
                            .parents(app.$accordions)
                                .toggleClass(app.classes.open);
                    });
    };

    /**
    *    Start Isotope
    */
    app.startIsotope = function() {
        function filterItems($masonry, filters) {
            $masonry.isotope({ filter: filters });
        };

        $('.grid').each(function() {
            var $this = $(this);
            var $gridContainer = $this.find('.grid__content');
            var filters = [];

            $gridContainer.isotope({
                layoutMode: 'vertical'
            });

            $this
                .find('.grid__filter .checkbox input')
                    .on('change', function() {
                        var filter = $(this).data('filter');

                        if($(this).is(':checked') && filter) {
                            filters.push(filter);

                            filterItems($gridContainer, filters.toString());
                        } else if(!$(this).is(':checked') && filter) {
                            filters.splice(filters.indexOf(filter), 1);

                            filterItems($gridContainer, filters.toString());
                        }
                    });
        });
    };

    /**
    *    Scroll Animations
    */
    app.scrollAnimations = function() {
        app
            .$win
                .on('scroll load', function() {
                    app
                        .$animateElements
                            .each(function() {
                                if(app.isInViewport($(this), 150)) {
                                    $(this).addClass(app.classes.animated);
                                }
                            });
                });
   };

   /**
   *    Handle videos
   */
   app.handleVideos = function() {

       /*
       *  Iframe Videos
       */
        app
            .$doc
                .on('click', '.video__popup .video__btn', function(e) {
                    e.preventDefault();

                    var $video = $(this).parents('.video__popup').find('iframe');
                    var src = $video.attr('src');

                    $video.attr('src', src + '?autoplay=1');
                });

        app
            .$doc
                .on('click', '.video__popup-close', function(e) {
                    e.preventDefault();

                    var $video = $(this).parents('.video__popup').find('iframe');
                    var src = $video.attr('src').replace('?autoplay=1', '');

                    $video.attr('src', src);

                });

       // While in slider
       $('.widget__slider .video__btn').on('click', function() {
           $(this)
               .parents('.video')
                    .clone()
                        .appendTo('body');
       });


       app
           .$doc
                .on('click', '.video-slider .video__popup-close', function(e) {
                    e.preventDefault();

                    $(this)
                        .parents('.video')
                            .remove();
                });

       // Video Small
       // $('.video-small .video__image .video__btn').on('click', function(e) {
       //     e.preventDefault();
       //
       //     $(this)
       //         .parents('.video__image')
       //              .siblings('.video__popup')
       //                  .addClass('visible');
       // });

       $('.video-small .btn-close').on('click', function(e) {
           e.preventDefault();

           $(this)
               .closest('.video__popup')
                    .removeClass('visible');

       });

       $('.video-small .video__poster .video__btn').on('click', function(e) {
           e.preventDefault();

           $(this)
               .closest('.video__poster')
                    .addClass('hide');

       });

        /**
        *    HTML videos
        */
       $('.video-intro .video__btn').on('click', function(e) {
           e.preventDefault();

           var $iframe = $(this).parents('.video-intro').find('iframe');
           var src = $(this).parents('.video-intro').find('iframe').attr('src');

           $(this)
               .parent()
                     .addClass('video__overlay--hidden');

            $iframe.attr('src', src + '?autoplay=1');
       });
   };

  $('#strongernav').onePageNav({
    currentClass: 'current',
    changeHash: false,
    scrollSpeed: 750,
    scrollThreshold: 0.5,
    filter: '',
    easing: 'swing'
  });

  $('#strongersubnav').onePageNav({
    currentClass: 'current',
    changeHash: false,
    scrollSpeed: 750,
    scrollThreshold: 0.5,
    filter: '',
    easing: 'swing'
  });

  $('#strongerlilsubnav').onePageNav({
    currentClass: 'current',
    changeHash: false,
    scrollSpeed: 750,
    scrollThreshold: 0.5,
    filter: '',
    easing: 'swing'
  });

   /**
   *    Start Maps
   */
   app.startMaps = function() {
        $('.map').each(function() {
            var $this = $(this);
            var mapdata = $this.data('map');
            var mapstyle = JSON.parse($this.data('mapstyle'));

            var map = new google.maps.Map($this.get(0), {
                center: new google.maps.LatLng(mapdata.center.lat , mapdata.center.lng),
                zoom: mapdata.zoom,
                styles: mapstyle.styles,
            });

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(mapdata.center.lat , mapdata.center.lng),
                map: map,
                icon: $this.data('pin')
            });
        });
   };

    /**
    *   Sticky Nav
    */
    app.stickyNav = function() {
        if ( app.$subnav.length ) {
            var navPosition = app.$subnav.offset().top;

            app
                .$win
                    .on('scroll load', function() {
                        app
                            .$subnav
                                .toggleClass('sticky', app.winST + app.$header.outerHeight() >= navPosition);
                    });
        }
    };

    /**
    *   Scrollspy
    */
    app.scrollSpy = function() {
        app
            .$win
                .on('load', function() {
                    app
                        .$ssTrigger
                            .on('click', function(e) {
                                e.preventDefault();

                                var $target = $($(this).attr('href'));

                                $('html, body').animate({
                                    scrollTop: $target.offset().top - (app.$header.outerHeight() + app.$subnav.outerHeight()) - 40
                                }, 0);

                                $('.nav-large ul').removeClass('visible');
                            });
                });
    };

    /**
    *    Is in viewport util
    */
    app.isInViewport = function($element, offset) {
        var offset = offset || 0;
        return app.winST + app.$win.outerHeight() - offset > $element.offset().top && app.winST < $element.offset().top + $element.outerHeight() ? true : false;
    };

    /**
    *    Load data util
    */
    app.loadData = function(url) {
        return $.ajax({
            url: url
        });
    };


    app.ajaxLoad = function() {
        app
            .$doc
                .on('click', '.paging-ajax li a', function(e) {
                    e.preventDefault();
                    var $currentVideos = $('.videos__body');
                    if (!app.loading) {
                        app.loading = true;

                        $.ajax({
                            url: $(this).attr('href'),
                            beforeSend: function() {
                                $currentVideos.addClass('loading');
                            },
                            success: function(response) {
                                var $newVideos = $(response).find('.videos__body');
                                $newVideos.find('.col-md-4').removeClass('animate');
                                $newVideos.find('.paging').removeClass('animate');

                                $currentVideos.html($newVideos.children());
                                $currentVideos.removeClass('loading');
                                app.loading = false;
                            }
                        });
                    }
                });
    };

    app.magnificShare = function() {
        $('.ajax-popup-share').magnificPopup({
          type: 'ajax',
          closeMarkup: '<button title="%title%" type="button" class="mfp-close btn-close"><span></span><span></span></button>'
        });

        $('.inline-popup').magnificPopup({
            type: 'inline',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close btn-close btn-close-dark"><span></span><span></span></button>'
        });

      $('.auto-popup').magnificPopup({
        type: 'inline',
        closeMarkup: '<button title="%title%" type="button" class="mfp-close btn-close btn-close-dark"><span></span><span></span></button>'
      });

        $('.commit-popup').magnificPopup({
          type: 'inline'
        });

        $('.video-popup').magnificPopup({
            type: 'iframe',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close btn-close"><span></span><span></span></button>'
        });

        $('.ajax-popup-content').magnificPopup({
          type: 'ajax',
          closeMarkup: '<button title="%title%" type="button" class="mfp-close btn-close"><span></span><span></span></button>',
          callbacks: {
                parseAjax: function(response) {
                    response.data = $(response.data).find('.main');
                },
            }
        });
    };

})(jQuery, document, window, window.app || {});

/**
*    Start App
*/
jQuery(document).ready(function() {
    app.init();
});

window.initMap = app.startMaps();
